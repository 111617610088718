import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Injector
} from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { IPointOfSaleDefinitionDTO } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'abp-modal-header',
  templateUrl: './abp-modal-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbpModalHeaderComponent extends AppComponentBase {
  @Input() title: string;

  @Output() onCloseClick = new EventEmitter<number>();

  constructor(injector: Injector) {
    super(injector);
  }
}

export class PointOfSaleDefinitionDTO implements IPointOfSaleDefinitionDTO {
  id: number;
  companyBranchId: number;
  statisticaL_CODE: string | undefined;
  ocF_Code: number;
  posCode: string | undefined;
  shortDescription: string | undefined;
  posAdress: string | undefined;
  personInChargeNID: string | undefined;
  personInChargeNatCode: number | undefined;
  telNo: string | undefined;
  faxNo: string | undefined;
  email: string | undefined;
  gpsAdress: string | undefined;
  imageURL: string | undefined;
  onlineId: number | undefined;
  january: number | undefined;
  february: number | undefined;
  march: number | undefined;
  april: number | undefined;
  may: number | undefined;
  june: number | undefined;
  july: number | undefined;
  august: number | undefined;
  september: number | undefined;
  october: number | undefined;
  november: number | undefined;
  december: number | undefined;
  isAuction: boolean | undefined;
  displayProductName: boolean;
  displayAvailableQuantity: boolean;
  displayProductPrice: boolean | undefined;
  displayCompanyName: boolean | undefined;
  displayDiscountBonus: boolean | undefined;
  priceInclusiveTax: boolean | undefined;
  displayProductUnit: boolean | undefined;
  displayProductWithoutCredit: boolean | undefined;
  printCashierVoucher: number | undefined;
  printBigCashierVoucher: boolean;
  password: string | undefined;
  code: string | undefined;

  saturday: number | undefined;
  sunday: number | undefined;
  monday: number | undefined;
  tuesday: number | undefined;
  wednesday: number | undefined;
  thursday: number | undefined;
  friday: number | undefined;

  constructor(data?: IPointOfSaleDefinitionDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.companyBranchId = _data["companyBranchId"];
      this.statisticaL_CODE = _data["statisticaL_CODE"];
      this.ocF_Code = _data["ocF_Code"];
      this.posCode = _data["posCode"];
      this.shortDescription = _data["shortDescription"];
      this.posAdress = _data["posAdress"];
      this.personInChargeNID = _data["personInChargeNID"];
      this.personInChargeNatCode = _data["personInChargeNatCode"];
      this.telNo = _data["telNo"];
      this.faxNo = _data["faxNo"];
      this.email = _data["email"];
      this.gpsAdress = _data["gpsAdress"];
      this.imageURL = _data["imageURL"];
      this.onlineId = _data["onlineId"];
      this.january = _data["january"];
      this.february = _data["february"];
      this.march = _data["march"];
      this.april = _data["april"];
      this.may = _data["may"];
      this.june = _data["june"];
      this.july = _data["july"];
      this.august = _data["august"];
      this.september = _data["september"];
      this.october = _data["october"];
      this.november = _data["november"];
      this.december = _data["december"];
      this.isAuction = _data["isAuction"];
      this.displayProductName = _data["displayProductName"];
      this.displayAvailableQuantity = _data["displayAvailableQuantity"];
      this.displayProductPrice = _data["displayProductPrice"];
      this.displayCompanyName = _data["displayCompanyName"];
      this.displayDiscountBonus = _data["displayDiscountBonus"];
      this.priceInclusiveTax = _data["priceInclusiveTax"];
      this.displayProductUnit = _data["displayProductUnit"];
      this.displayProductWithoutCredit = _data["displayProductWithoutCredit"];
      this.printCashierVoucher = _data["printCashierVoucher"];
      this.printBigCashierVoucher = _data["printBigCashierVoucher"];
      this.password = _data["password"];
      this.code = _data["code"];
      this.saturday = _data["saturday"];
      this.sunday = _data["sunday"];
      this.monday = _data["monday"];
      this.tuesday = _data["tuesday"];
      this.wednesday = _data["wednesday"];
      this.thursday = _data["thursday"];
      this.friday = _data["friday"];
    }
  }

  static fromJS(data: any): PointOfSaleDefinitionDTO {
    data = typeof data === "object" ? data : {};
    let result = new PointOfSaleDefinitionDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["id"] = this.id;
    data["companyBranchId"] = this.companyBranchId;
    data["statisticaL_CODE"] = this.statisticaL_CODE;
    data["ocF_Code"] = this.ocF_Code;
    data["posCode"] = this.posCode;
    data["shortDescription"] = this.shortDescription;
    data["posAdress"] = this.posAdress;
    data["personInChargeNID"] = this.personInChargeNID;
    data["personInChargeNatCode"] = this.personInChargeNatCode;
    data["telNo"] = this.telNo;
    data["faxNo"] = this.faxNo;
    data["email"] = this.email;
    data["gpsAdress"] = this.gpsAdress;
    data["imageURL"] = this.imageURL;
    data["onlineId"] = this.onlineId;
    data["january"] = this.january;
    data["february"] = this.february;
    data["march"] = this.march;
    data["april"] = this.april;
    data["may"] = this.may;
    data["june"] = this.june;
    data["july"] = this.july;
    data["august"] = this.august;
    data["september"] = this.september;
    data["october"] = this.october;
    data["november"] = this.november;
    data["december"] = this.december;
    data["isAuction"] = this.isAuction;
    data["displayProductName"] = this.displayProductName;
    data["displayAvailableQuantity"] = this.displayAvailableQuantity;
    data["displayProductPrice"] = this.displayProductPrice;
    data["displayCompanyName"] = this.displayCompanyName;
    data["displayDiscountBonus"] = this.displayDiscountBonus;
    data["priceInclusiveTax"] = this.priceInclusiveTax;
    data["displayProductUnit"] = this.displayProductUnit;
    data["displayProductWithoutCredit"] = this.displayProductWithoutCredit;
    data["printCashierVoucher"] = this.printCashierVoucher;
    data["printBigCashierVoucher"] = this.printBigCashierVoucher;
    data["password"] = this.password;
    data["code"] = this.code;
    data["saturday"] = this.saturday;
    data["sunday"] = this.sunday;
    data["monday"] = this.monday;
    data["tuesday"] = this.tuesday;
    data["wednesday"] = this.wednesday;
    data["thursday"] = this.thursday;
    data["friday"] = this.friday;

    return data;
  }

  clone(): PointOfSaleDefinitionDTO {
    const json = this.toJSON();
    let result = new PointOfSaleDefinitionDTO();
    result.init(json);
    return result;
  }
}
